.style {
  display: flex;
}

.menu {
  margin-left: 15.5em;
  margin-top: -12px;
  margin-bottom: -12px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto Condensed", sans-serif !important;
  font-style: italic;
}

p {
  font-size: 18px;
}

.eco {
  font-size: 30px;
  color: #4d4d4d;
  font-style: italic;
}

.invest_section_title {
  font-size: 22px;
  color: #4d4d4d;
  font-style: italic;
}

.sidebar {
  color: aqua;
}

.form_input {
  padding: 13px !important;
}
.ui.form input,
.ui.form select,
.ui.form textarea {
  background: #ffc20261 !important;
  border-color: #fdc501 !important;
}
.ui.form input::placeholder,
.ui.form textarea::placeholder {
  color: #777777;
}
.order_desc {
  width: 65% !important;
  font-size: 20px;
  /* margin-bottom: -20px; */
}
.form_input:focus,
.form_textarea textarea:focus {
  border-color: #74747400 !important;
  /* background-color: #ffffff !important; */
}

.form_textarea {
  border-color: #fdc501 !important;
}

.why_section_title {
  text-align: center;
  padding: 25px 0px;
  font-size: 34px;
  margin-bottom: 20px;
}

.tg {
  margin-top: 30px;
  display: none;
}

.home_page_navbar {
  background-color: #303030;
  /* position: fixed; */
  width: 100%;
  z-index: 1000;
}
.ui.secondary.menu .item {
  color: #ffffff !important;
  font-size: 18px !important;
}

.listmenu {
  list-style: none;
  margin-left: 50px;
  margin-top: 17px;
}

.listmenu a {
  color: white;
  font-size: 17px;
  cursor: pointer;
}

.listgrood a {
  color: white;
  font-size: 17px;
  cursor: pointer;
}

.listgrood {
  margin-left: 457px;
  list-style: none;
  margin-top: 17px;
}

.logo {
  list-style: none;
  margin-top: 12px;
  margin-bottom: 10px;
  cursor: pointer;
}
.logo img {
  width: 40% !important;
}

.listbtn {
  margin-left: 50px;
  margin-top: 10.5px;
  list-style: none;
}
.listbtn button {
  font-style: italic !important;
  position: relative;
  top: 8px;
}
.right .item {
  font-style: italic !important;
}

.ui.primary.button {
  background-color: #f5c644 !important;
  color: black !important;
}

.ui.massive.image {
  margin-left: 110px !important;
}

.ui[class*="vertically divided"].grid > .row {
  margin-top: 50vh !important;
}

.mybike {
  color: black;
  font-size: 45px;
  margin-right: -159px;
}

.ui.grid > .row > .column {
  text-align: justify !important;
}

.better {
  font-size: 67px;
  color: #f5c644;
  margin-right: -159px;
}

.hh2 {
  color: #f5c644;
}

.whatgrood {
  text-align: center;
  padding-top: 100px;
}

.what_page_background_color {
  background: #eff9fb;
}

.titlesize {
  font-size: 56px;
  margin-top: 60%;
}

.imagestyle {
  padding-bottom: 100px;
}

.whygrood {
  padding: 100px 0px;
}

.paragrap {
  margin-top: -14px;
}

.whygrood {
  color: black;
}

.hwhy {
  color: #f5c644;
}
.banner_ride {
  position: relative;
  background: url("/image/phnompenh.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
}
.banner button {
  padding: 15px 40px !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-style: italic !important;
}

.ridebike {
  background-image: url("/BG.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.banner_image {
  position: absolute;
  top: 20%;
  right: -15%;
}
.banner_image img {
  width: 80% !important;
}
.banner p {
  width: 50% !important;
}
.footer .ui.grid {
  margin-bottom: 0px !important;
}
.under_footer img {
  height: 25px;
  position: relative;
  top: 5px;
}

.banner {
  margin: 10em 0px;
}
.whatgrood p,
.why_section_desc {
  width: 70%;
  padding: 20px 0px;
  margin: 0px auto;
}

.why_section_subtitle {
  font-weight: 500;
  color: #7a7a7a;
  font-style: italic;
  position: relative;
  top: -10px;
}

.title {
  text-align: center;
}

.ui.grid > [class*="three column"].row > .column {
  width: 10.33333333% !important;
}

.mygrid {
  text-align: center;
  margin-top: 50px;
}

.ui.grid > .row > .column {
  text-align: center !important;
}

.pre_order {
  padding-top: 100px;
}
input::placeholder {
  color: black;
  font-size: 1m;
}

.submitBtn {
  /* background-color: #4b4a4a !important; */
  font-size: 15px;
  width: 30%;
  float: right;
  color: #ffffff !important;
}
.preOrderBtn {
  background: #fdc501 !important;
}

.ui.form {
  width: 30%;
  margin-top: 70px;
}

.footer {
  padding: 10px 0px;
}

.grood {
  width: 56%;
  margin-top: 21.9%;
}

.copyright {
  margin-left: 6.5em;
  margin-top: -11px;
}
.ui.segment {
  background-color: transparent !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  border-radius: 0em !important;
  border: 0px solid rgba(0, 0, 0, 0) !important;
}

.slide {
  background: rosybrown;
  position: fixed;
  height: 100%;
  display: none;
  margin-top: 0;
  z-index: 1000;
}
.blackColor {
  background-color: #303030 !important;
  border: 3px solid #ffffff;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  float: right;
  margin-left: 10px;
}
.darkGreenColor {
  background-color: #1d3c27 !important;
  border: 3px solid #ffffff;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  float: right;
}

.menuside {
  margin-top: 100px;
  color: #fdc501;
}
.order_title {
  font-size: 40px !important;
  font-style: italic;
}

.group_btn {
  display: none;
}
.pre_order_background {
  background-color: #eff9fb;
  padding: 70px 0px !important;
  position: relative;
  padding-bottom: 10% !important;
}
.discount_image {
  position: fixed;
  height: 230px;
  top: 62px;
  z-index: 10;
  cursor: pointer;
}
.pageBtn {
  padding: 15px 40px !important;
}
.groodKit img {
  width: 650px !important;
}
.pre_order_image {
  background: url("/image/phnompenh.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  height: 500px;
  position: relative;
  width: 400px;
  width: 100%;
  height: 1;
  position: absolute;
  right: -23vw;
  z-index: 0;
  /* top: 46%; */
  bottom: -12%;
}
.preorder_width {
  width: 100% !important;
  padding: 0px 10vw !important;
  margin-top: 30px !important;
}
.under_footer {
  text-align: right;
  color: #ffffff;
  position: relative;
  top: 15px;
}
.footer_image {
  width: 10vw !important;
  position: relative;
  top: 25px;
}
.pre_order_label {
  margin: 10px 0px !important;
  font-weight: 500;
  font-style: italic;
  font-size: 16px !important;
}
.ui.radio.checkbox label {
  font-size: 17px !important;
}
.ui.radio.checkbox input {
  min-height: 40px !important;
  min-width: 40px !important;
}
.error_message {
  color: #ed4337;
  font-size: 16px;
}

.ui.table {
  width: 100%;
  background: #dadfe2;
  margin: 1em 0;
  margin-top: 3em !important;
  margin-bottom: 3em !important;
  border: 0px solid rgba(34, 36, 38, 0) !important;
  border-radius: 0px !important;
}
.ui.table td {
  font-size: 15px !important;
}
.grood_kit_section {
  width: 70%;
  margin: 0px auto;
}

.imagestyle img {
  width: 80% !important;
}
.phone_menu {
  display: none;
}
.ui.fixed.menu {
  background-color: #303030;
}
select.ui.dropdown {
  height: 43px;
  padding: 0.5em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  visibility: visible;
  box-shadow: none;
}
.pre_order_grood_bike_image img {
  width: 20% !important;
  position: absolute;
  left: 5%;
  top: 29%;
}
.footer_section {
  position: relative;
}
.firstBike {
  width: 10%;
  position: absolute;
  top: -135%;
  left: 0;
  animation-name: cycling;
  animation-duration: 20s;
  /* animation-fill-mode: both; */
  animation-iteration-count: infinite;
  animation-direction: reverse;
  animation-timing-function: linear;
}
.secondBike {
  width: 10%;
  position: absolute;
  top: -100%;
  left: 0;
  animation-name: cycling;
  animation-duration: 20s;
  /* animation-fill-mode: both; */
  animation-iteration-count: infinite;
  animation-direction: reverse;
  animation-timing-function: linear;
}
.groodPP {
  position: relative;
}

.grood_pp_image_background {
  background: url("/image/phnompenh.png");
  height: 355px;
  background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}

@keyframes cycling {
  0% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
  }
  to {
    transform: translateX(2000px);
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
  }
}
.footer_sw_logo {
  height: 80px !important;
}

/*=====================================================
=            Bootstrap 2.3.2 Media Queries            =
=====================================================*/
@media only screen and (max-width: 1600px) {
  .container {
    width: 70% !important;
  }
  .banner_image {
    position: absolute;
    top: 20%;
    right: -25%;
  }
  .discount_image {
    height: 200px;
  }
  .pre_order_grood_bike_image img {
    width: 20% !important;
    position: absolute;
    left: 3%;
    top: 29%;
  }
  .secondBike {
    display: none;
  }
}

@media only screen and (max-width: 1300px) {
  .container {
    width: 80% !important;
  }
  .banner_image {
    display: none;
  }
  .banner {
    margin: 0px 0px;
  }
  .banner p {
    width: 100% !important;
  }
  .banner_ride {
    padding: 50px 40px;
  }
  .imagestyle img {
    width: 80% !important;
  }
  .titlesize {
    font-size: 40px;
  }
  .whatgrood {
    text-align: center;
    padding-top: 50px;
  }
  .why_section_desc {
    margin-bottom: 30px;
  }
  .whatgrood p {
    width: 100%;
    padding: 0px 50px;
    margin: 0px auto;
    text-align: left;
  }
  .whygrood {
    padding: 40px 0px;
  }
  .why_section_title {
    font-size: 24px;
    text-align: left;
  }
  .eco {
    font-size: 20px;
  }
  .ui.segment {
    margin: 26px 0px !important;
  }
  .order_title {
    font-size: 30px !important;
  }
  .pre_order_background {
    padding: 30px 0px !important;
  }
  .pre_order_image {
    display: none;
  }
  .ui.primary.submit.button {
    float: none;
    width: 40%;
  }
  .mybike {
    color: black;
    font-size: 25px;
    margin-right: 0px;
  }
  .better {
    font-size: 34px;
    color: #f5c644;
    margin-right: 0px;
  }
  .changeColorDiv {
    display: none;
  }
  .discount_image {
    height: 120px !important;
  }
  .grood_pp_image_background img {
    width: 450px !important;
  }
  .pre_order_grood_bike_image img {
    display: none;
  }
  .ui.segment {
    padding: 15px 30px !important;
  }
}

@media only screen and (max-width: 979px) {
}

@media only screen and (max-width: 767px) {
  .changeColorDiv {
    display: none;
  }
  .discount_image {
    position: absolute;
    height: 111px;
    top: -11px;
  }
  .ui.container {
    width: 100% !important;
    margin: 0px !important;
  }
  .submitBtn {
    float: none;
    width: 60%;
  }
  .ui.secondary.menu .web_menu_list {
    display: none;
  }
  .ui.secondary.menu .item:hover {
    background: transparent;
  }
  .ui.secondary.menu .listbtn {
    display: none;
  }
  .mybike {
    color: black;
    font-size: 25px;
    margin-right: 0px;
  }
  .better {
    font-size: 34px;
    color: #f5c644;
    margin-right: 0px;
  }
  .whatgrood p,
  .why_section_desc {
    width: 90%;
    padding: 20px 0px;
    margin: 0px auto;
    text-align: left;
  }
  .banner_ride {
    margin-top: 40px;
    padding: 60px 20px;
  }
  .why_section_title {
    font-size: 22px;
    text-align: left;
    padding: 0px 15px !important;
  }
  .preorder_width {
    padding: 0px 20px !important;
  }
  .ui.left.visible.sidebar {
    background-color: #4b4a4a;
    width: 65% !important;
  }
  .ui.labeled.icon.menu .item {
    color: #ffffff !important;
    text-align: left;
  }
  .logo img {
    width: 30% !important;
    position: relative;
    left: 10%;
  }
  .ridebike {
    padding: 15px 0px;
  }
  .phone_menu {
    height: 25px;
    position: relative;
    top: 15px;
    right: 20px;
    display: inline;
  }
  .footer_image {
    display: none !important;
  }
  .under_footer {
    text-align: center;
    color: #ffffff;
    position: relative;
    top: -10px;
  }
  .copyright {
    margin-left: 0px;
    margin-top: -14px;
  }
  .mobile_back {
    background-color: #000000d1;
    position: fixed;
    height: 100%;
  }
  .pageBtn {
    padding: 15px 40px !important;
  }
  .imagestyle img {
    width: 100% !important;
  }
  .imagestyle {
    padding-bottom: 50px;
  }
  .grood_pp_image_background {
    background-size: contain;
    height: 300px;
  }
  .groodKit img {
    width: 70% !important;
  }
  .grood_kit_section {
    width: 90%;
    margin: 0px auto;
  }
  .discount_image {
    display: none;
  }
  .order_desc {
    width: 90% !important;
    font-size: 20px;
    margin-bottom: -20px;
  }
  .pre_order_background {
    padding: 50px 0px !important;
  }
  .featureImg {
    display: none !important;
  }
  .featureTable {
    padding: 0px 15px !important;
  }
  .preOrderBtn {
    padding: 12px 30px !important;
  }
  .grood_kit_section {
    margin-top: -45px;
  }
}

@media only screen and (max-width: 480px) {
  .grood_pp_image_background img {
    width: 80% !important;
  }
  .featureTable {
    margin-top: -40px !important;
  }
  .groodKit img {
    width: 70% !important;
  }
}

@media only screen and (max-width: 320px) {
}
